export const firebaseConfig= {
    "apiKey": "AIzaSyA-IELeRwbzNYBWt7tq4PRejxQ7XaxF6BU",
    "authDomain": "truenorth-158d3.firebaseapp.com",
    "projectId": "truenorth-158d3",
    "storageBucket": "truenorth-158d3.appspot.com",
    "messagingSenderId": "671208199174",
    "appId": "1:671208199174:web:3e6361de0983770d041f87",
    "measurementId": "G-K09MQ0NHK3",
    "serviceAccountId": "firebase-adminsdk-2yiw1@truenorth-158d3.iam.gserviceaccount.com",
    "credential": "cert(serviceAccount)",
    "databaseURL": "https://truenorth-158d3-default-rtdb.firebaseio.com",
    "hubspotApiBearerToken" : "pat-na1-1fa325c9-57ba-45fb-a6e9-6a2415746fa7",
    "secret" : "Al1YurBas3sali9ui@p"
};
