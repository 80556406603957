import { createSlice } from '@reduxjs/toolkit'
import { fbAuth, fbDb } from '../../app/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null, // Firebase Auth user
    firestoreUser: null, // Firestore user document
    isLoading: true,
  },
  reducers: {
    setAuthUser: (state, action) => {
      state.currentUser = action.payload
      state.isLoading = false
    },
    setFirestoreUser: (state, action) => {
      state.firestoreUser = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { setAuthUser, setFirestoreUser, setLoading } = authSlice.actions

// Async action to initialize Firebase authentication
export const initializeAuthListener = () => async (dispatch) => {
  dispatch(setLoading(true))

  const unsub = onAuthStateChanged(fbAuth, async (user) => {
    if (user) {
      // Extract required properties from the Firebase User object
      const { displayName, email, phoneNumber, uid } = user

      // Dispatch action to set the authenticated user
      dispatch(setAuthUser({ displayName, email, phoneNumber, uid }))

      // Fetch user data from Firestore
      const docRef = doc(fbDb, 'users', uid)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const userData = docSnap.data()
        dispatch(setFirestoreUser(userData))
      } else {
        console.log('No such document!')
      }
    } else {
      dispatch(setAuthUser(null))
      dispatch(setFirestoreUser(null)) // Clear Firestore user data as well
    }

    dispatch(setLoading(false))
  })

  return () => {
    unsub()
  }
}

// Selectors to access auth state in Redux store
export const selectCurrentUser = (state) => state.auth.currentUser
export const selectFirestoreUser = (state) => state.auth.firestoreUser
export const authIsLoading = (state) => state.auth.isLoading

export default authSlice.reducer
