import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux'
import SuspenseContent from './containers/SuspenseContent';
import { initializeAuthListener } from './features/auth/authSlice';

const root = ReactDOM.createRoot(document.getElementById('root'));

store.dispatch(initializeAuthListener());

root.render(
  // <React.StrictMode>
  <Suspense fallback={<SuspenseContent />}>
    <Provider store={store}>      
          <App />     
    </Provider>
  </Suspense>
  // </React.StrictMode>
);