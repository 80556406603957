import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        title: "",
        isOpen: false,
        bodyType: "",
        size: "md", // Set default size here
        extraObject: {}
    },
    reducers: {
        openModal: (state, action) => {
            const { title, bodyType, extraObject, size } = action.payload;
            const serializedExtraObject = serializeExtraObject(extraObject); // Serialize extraObject
            return {
                ...state,
                isOpen: true,
                bodyType,
                title,
                size: size || 'md',
                extraObject: serializedExtraObject
            };
        },
        closeModal: (state) => {
            state.isOpen = false;
            state.bodyType = "";
            state.title = "";
            state.extraObject = {};
        }
    }
});

export const serializeExtraObject = (extraObject) => {
    if (!extraObject) {
        return extraObject;
    }

    if (extraObject.patient) {
        const { patient } = extraObject;
        const serializedPatient = { ...patient };

        if (serializedPatient.visitStart instanceof Date) {
            serializedPatient.visitStart = serializedPatient.visitStart.toISOString();
        }

        if (serializedPatient.createdAt instanceof Date) {
            serializedPatient.createdAt = serializedPatient.createdAt.toISOString();
        }

        if (serializedPatient.updatedAt instanceof Date) {
            serializedPatient.updatedAt = serializedPatient.updatedAt.toISOString();
        }

        // Handle other fields if needed

        return { patient: serializedPatient };
    }

    return extraObject;
};





export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
