import { createSlice } from '@reduxjs/toolkit'
import { RIGHT_DRAWER_TYPES } from '../../utils/globalConstantUtil'

export const rightDrawerSlice = createSlice({
  name: 'rightDrawer',
  initialState: {
    header: '',
    isOpen: false,
    bodyType: '',
    extraObject: {},
  },
  reducers: {
    openRightDrawer: (state, action) => {
      const { header, bodyType, extraObject } = action.payload
      const serializedExtraObject = serializeExtraObject(extraObject)
      state.isOpen = true
      state.bodyType = bodyType
      state.header = header
      state.isVisitChatOpen = bodyType === RIGHT_DRAWER_TYPES.VISIT_CHAT
      state.extraObject = serializedExtraObject
    },
    closeRightDrawer: (state) => {
      state.isOpen = false
      state.isVisitChatOpen = false
      state.bodyType = ''
      state.header = ''
      state.extraObject = {}
    },
  },
})

export const serializeExtraObject = (extraObject) => {
  if (!extraObject) {
    return extraObject
  }

  if (extraObject.patient) {
    const { patient } = extraObject
    const serializedPatient = { ...patient }

    if (serializedPatient.visitStart instanceof Date) {
      serializedPatient.visitStart = serializedPatient.visitStart.toISOString()
    }

    if (serializedPatient.createdAt instanceof Date) {
      serializedPatient.createdAt = serializedPatient.createdAt.toISOString()
    }

    if (serializedPatient.updatedAt instanceof Date) {
      serializedPatient.updatedAt = serializedPatient.updatedAt.toISOString()
    }

    return { patient: serializedPatient }
  }

  return extraObject
}

export const { openRightDrawer, closeRightDrawer } = rightDrawerSlice.actions
export const selectIsVisitChatOpen = (state) => state.rightDrawer.isVisitChatOpen

export default rightDrawerSlice.reducer
