import {fbLog, fbSignIn, fbSync, fbSyncContactToUser, fbExpireSession} from "./firebase";
import {browserSessionPersistence, getAuth, setPersistence, signInWithCustomToken} from "firebase/auth";
import {clearAllCookies} from "../utils/cookie.js";
import axios from "axios";

const sendLog = (msg) => {
  fbLog( { log: msg })
    .then((response) => {

    })
    .catch((error) => {
      console.error("Error calling Cloud Function:", error);
    });
}

export const testSync = async () => {
  await fbSync({});
}

const getAxiosOptions =
  (apiUrl, requestMethod, requestData) => {
    return {
      method: requestMethod,
      headers: {
        "content-type": "application/json"
      },
      data: requestData,
      url: apiUrl
    };
};

export const checkSession = async () => {  
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (currentUser) {
    const idToken = await currentUser.getIdToken(false); // don't refresh
    
    const apiUrl = "https://truenorth.caselmedical.com/checkSessionValid";
    // const apiUrl = "http://127.0.0.1:5004/truenorth-158d3/us-central1/checkSessionValid";
    const options = getAxiosOptions(apiUrl, "POST", {uid: currentUser.uid, idToken: idToken});
    return axios(options)
      .then(function(response) {
        if (response?.status === 200) {
          return (response?.data?.status === 200);
        }
        return false;
      })
      .catch(function(error) {
        console.log("Errors", error.toJSON);
        return false;
      })
  }
  return false;
}

export const checkAuth = async () => {
  const auth = getAuth();  
  const currentUser = auth.currentUser;
  if (currentUser) {
    const validSession = await checkSession();
    if (validSession && currentUser.uid) {
      return {uid: currentUser.uid, setcompany: false}
    }
    
    if (!validSession) {
      await logout();
      return false;
    }
  }

  try {
    console.log("starting signin");
    const response = await fbSignIn({cookie: document.cookie});
    if (response?.data && response.data?.status === 200 && response.data?.token) {
      const token = response.data.token;
      const profile = response.data.data;
      const setcompany = response.data.setcompany;
      
      await setPersistence(auth, browserSessionPersistence);
      const userCredential = await signInWithCustomToken(auth, token);
      let user = userCredential.user;
      const idToken = await user.getIdToken();
      // Session login endpoint is queried and the session cookie is set.
      // CSRF token should be sent along with request.
      // const csrfToken = getCookie('csrfToken')
      const apiUrl = "https://truenorth.caselmedical.com/createSessionLogin";
      // const apiUrl = "http://127.0.0.1:5004/truenorth-158d3/us-central1/createSessionLogin";
      const data = {
        idToken: idToken,
        csrfToken: "",
      };
      const options = getAxiosOptions(apiUrl, "POST", data);
      const cookieResponse = await axios(options);
      if (cookieResponse?.status === 200) {
        const validSession = await checkSession();
        if (validSession && user.uid) {
          await fbSyncContactToUser({
            uid: user.uid,
            data: profile
          });
          
          // TESTING ONLY
          // await fbSync();

          return {uid: user.uid, setcompany: setcompany};
        }
        else {
          console.log("Session Expired - SignOUT");          
        }
      }
      await auth.signOut();

      return false;
    }
    else if (response?.data && (response.data?.status === 400 || response.data?.status === 401 || response.data?.status === 403)) {
       // 400 = no hubspotutk, 401 = access denied, 403 = too many roles error
      return {status: response.data.status, error: response.data?.error};
    }
  } catch (error) {
    await auth.signOut();
    return false;
  }
  return false;
}

export const logout = async () => {
  const currentUser = getAuth().currentUser;
  await fbLog({log:"logout called: "+currentUser?.uid});
  if (currentUser && currentUser?.uid) {

    await fbLog({log:"clearAllCookies called: "+currentUser?.uid});
    // clear hubspotutk, __hstc, __hssc, __hssrc
    clearAllCookies();

    await fbLog({log:"signOut called: "+currentUser?.uid});
    await getAuth().signOut();

    await fbLog({log:"cleanUpSession called: "+currentUser?.uid});
    const apiUrl = "https://truenorth.caselmedical.com/cleanUpSession";
    // const apiUrl = "http://127.0.0.1:5004/truenorth-158d3/us-central1/cleanUpSession";
    const options = getAxiosOptions(apiUrl, "POST", {cookie: document.cookie});
    await axios(options);    

    await fbLog({log:"fbExpireSession called: "+currentUser?.uid});
    await fbExpireSession({uid: currentUser.uid});
  }
  window.location.href = 'https://portal.caselmedical.com/_hcms/mem/logout?redirect_url=/';
}