import { createSlice } from "@reduxjs/toolkit";

export const supportChatSlice = createSlice({
  name: 'supportChat',
  initialState: {
    hasUnreadStaff: false,
    hasUnreadAffiliate: false,
    unreadMessages: {},
    globalUnreadCount: 0,
    affiliateUnreadMessages: {},
    globalAffiliateUnreadCount: 0,
  },
  reducers: {
    showUnreadIndicator: (state, action) => {
      const { hasUnread, userType } = action.payload;
      if (userType === 'staff') {
        state.hasUnreadStaff = hasUnread;
      } else if (userType === 'affiliate') {
        state.hasUnreadAffiliate = hasUnread;
      }
    },
  
    setLastReadTimestamp: (state, action) => {
      const { chatId, timestamp, userType } = action.payload;
      if (userType === 'staff') {
        state.unreadMessages[chatId] = state.unreadMessages[chatId] || { lastRead: null, count: 0 };
        state.unreadMessages[chatId].lastRead = timestamp;
      } else if (userType === 'affiliate') {
        state.affiliateUnreadMessages[chatId] = state.affiliateUnreadMessages[chatId] || { lastRead: null, count: 0 };
        state.affiliateUnreadMessages[chatId].lastRead = timestamp; // Assume conversion to correct format occurs here if needed
      }
    },
    updateUnreadCount: (state, action) => {
      const { chatId, count, userType } = action.payload;
      console.log(`Updating unread count for ${chatId}: ${count} as ${userType}`);
      if (userType === 'staff') {
        state.unreadMessages[chatId] = state.unreadMessages[chatId] || { lastRead: null, count: 0 };
        state.unreadMessages[chatId].count = count;
      } else if (userType === 'affiliate') {
        state.affiliateUnreadMessages[chatId] = state.affiliateUnreadMessages[chatId] || { lastRead: null, count: 0 };
        state.affiliateUnreadMessages[chatId].count = count;
      }
      console.log('Updated state:', state);
    },
    updateGlobalUnreadCount: (state, action) => {
      const { count, userType } = action.payload;
      if (userType === 'staff') {
        state.globalUnreadCount = count;
      } else if (userType === 'affiliate') {
        state.globalAffiliateUnreadCount = count;
      }
    },

    
  }
});


export const selectHasUnreadStaff = (state) => state.supportChat.hasUnreadStaff;
export const selectHasUnreadAffiliate = (state) => state.supportChat.hasUnreadAffiliate;


export const { showUnreadIndicator, setLastReadTimestamp, updateUnreadCount, updateGlobalUnreadCount } = supportChatSlice.actions;
export default supportChatSlice.reducer;
