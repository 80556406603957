import React, { lazy, useEffect, useState } from 'react'
import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import { checkAuth, checkSession, logout, testSync } from './app/auth';
import { fbExpireSession, fbAuth } from "./app/firebase";
import initializeApp from './app/init';
import { Toaster } from 'react-hot-toast';
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from 'react-redux';
import { selectFirestoreUser } from './features/auth/authSlice';
import { onAuthStateChanged } from 'firebase/auth';
import Loader from "./components/Loader/Loader"

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
const PortalLogin = lazy(() => import('./pages/PortalLogin'))

// Initializing different libraries
initializeApp();

// testSync();

function App() {
  const [currentUid, setCurrentUid] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [needDefaultCompany, setNeedDefaultCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [status, setStatus] = useState(200);
  const firestoreUser = useSelector(selectFirestoreUser);

  const logoutNow = async () => {
    setCurrentUid(null);
    setLoggedOut(true);
    await logout();
  }

  const onIdle = async () => {
    await fbExpireSession({ uid: currentUid });
    logoutNow();
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    // onActive,
    // onAction,
    timeout: 1000 * 60 * 60 * 12, /* 12 hr */
    throttle: 500
  })

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);

    const unsub = onAuthStateChanged(fbAuth, (user) => {
      console.log('user', user);
      setCurrentUid(user ? user.uid : null);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const doAuth = async () => {
      // perform auth when initially coming to the site 
      // and user hasn't physically logged out
      console.log("referrer", document.referrer);
      console.log("currentUid", currentUid);
      if (currentUid == null) {

      }
      if (!loggedOut) {
        let uidStatus = await checkAuth();
        console.log("uidStatus", uidStatus);
        if (uidStatus) {
          setStatus(uidStatus?.status);
          if (uidStatus?.error) {
            if (uidStatus?.status === 400 && uidStatus?.error === "Cannot Login!") {
              setError('');
              setNavigateTo('/portal-login');
            }
            else {
              setError(uidStatus?.error);
            }
          }
          else {
            setNeedDefaultCompany(uidStatus.setcompany);
            setCurrentUid(uidStatus.uid);
          }
        }
        else {
          setLoggedOut(uidStatus === false);
        }
      }
    }
    doAuth();

  }, [loggedOut, currentUid]);

  useEffect(() => {
    if (!currentUid) {
      // Optionally set a navigateTo value here if you want unauthenticated users to be redirected somewhere
      return;
    }
    if (firestoreUser === undefined) {
      // Wait until firestoreUser is defined (loaded)
      return;
    }

    // Check if the user is an affiliate and if additional conditions are met
    const shouldRedirectToSettings = firestoreUser?.role?.affiliate === true &&
      (firestoreUser.defaultCompanyId === '' || firestoreUser.defaultCompanyId === undefined || needDefaultCompany);

    const path = shouldRedirectToSettings ? '/app/settings-profile' : '/app/active-visits';
    setNavigateTo(path);
  }, [currentUid, firestoreUser, needDefaultCompany]);

  if (error.length) return (<><div>Error: <span>{error}</span></div></>);
  if (isLoading) return (<>Loading... <Loader /></>);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/portal-login" element={<PortalLogin />} />

          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          {navigateTo && <Route path="*" element={<Navigate to={navigateTo} replace />} />}
        </Routes>
      </Router>
      <Toaster position="top-center" />
    </>
  )
}

export default App
