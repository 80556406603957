import { createSlice } from '@reduxjs/toolkit';
import { fbAreThereActiveStaffUsers } from '../../app/firebase';

export const activeUserSlice = createSlice({
  name: 'activeUser',
  initialState: {
    haveActiveStaffUser: false,
    isLoading: true,
  },
  reducers: {
    setActiveStaffUserStatus: (state, action) => {
      state.haveActiveStaffUser = action.payload;
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setActiveStaffUserStatus, setLoading } = activeUserSlice.actions;

export const checkActiveStaffUser = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await fbAreThereActiveStaffUsers();
    console.log("Cloud function result:", result); // Log the full result to debug

    if (result.data !== undefined && result.data !== null) {
      dispatch(setActiveStaffUserStatus(result.data));
    } else {
      console.error('No data returned from cloud function, or data is undefined/null');
      dispatch(setActiveStaffUserStatus(false));
    }
  } catch (error) {
    console.error('Failed to check for active staff user:', error);
    dispatch(setActiveStaffUserStatus(false));
  } finally {
    dispatch(setLoading(false));
  }
};

// Selector to access the state in Redux store
export const selectHaveActiveStaffUser = (state) => state.activeUser.haveActiveStaffUser;
export const activeUserIsLoading = (state) => state.activeUser.isLoading;

export default activeUserSlice.reducer;
