import { configureStore } from '@reduxjs/toolkit';
import rightDrawerReducer from '../features/common/rightDrawerSlice';
import headerReducer from '../features/common/headerSlice';
import modalReducer from '../features/common/modalSlice';
import pastVisitsReducer from '../features/pastvisits/pastVisitsSlice';
import visitReducer from '../features/visits/visitSlice';
import authReducer from '../features/auth/authSlice'
import activeUserReducer from '../features/auth/activeUserSlice'
import supportChatReducer from '../features/supportChat/supportChatSlice'

export const store = configureStore({
  reducer: {
    header: headerReducer,
    modal: modalReducer,
    rightDrawer: rightDrawerReducer,
    pastVisits: pastVisitsReducer,
    visits: visitReducer,
    auth: authReducer,
    activeUser: activeUserReducer,
    supportChat: supportChatReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore the openModal action
        ignoredActions: [
          'rightDrawer/openRightDrawer',
          'modal/openModal',
          'visits/updateCurrentVisitForChat',
          'visits/setCurrentVisitId'
        ],
        ignoredPaths: ['rightDrawer.extraObject', 'modal.extraObject'],
      },
    }),
});
